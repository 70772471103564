import { Component, Input } from '@angular/core';
import { Action } from 'src/app/models/caller.model';
import { ActionButtonComponent, IconType } from './action-button.component';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-action-buttons',
  template: `
  <div class="actions">
    <app-action-button *ngFor="let action of actions" title="{{action.title}}" [showText]="false" [action]="action">{{action.title}}</app-action-button>
  </div>
  `,
  styles: [
    `

.actions{
    display: flex;
    margin-bottom: 4px;
    gap: 4px;
    flex-wrap: wrap;
}

    `
  ],
  standalone: true,
  imports: [ActionButtonComponent, CommonModule]
})
export class ActionButtonsComponent{
  @Input() actions!: Action[] | undefined;
  iconType = IconType;
}

import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class WebViewInteropService {

  constructor() { }

  postMessageToClient(message: string)
  {
    window?.chrome?.webview?.postMessage(message); //we have to signal the native client that the iniliazation is completed. so we can load the call history
  }

  //get telemetry data from native client via webview
  getTelemetryFromClient(): string {
    return window?.chrome?.webview?.hostObjects.sync.interopClass.getClientInformation();
  }

  saveCallEntry(entry: any){
    try{
      window?.chrome?.webview?.hostObjects.sync.interopClass.SaveHistoryEntry(JSON.stringify(entry));//Call a function from interopClass in WPF C#
    }
    catch{
      console.log("SaveCallEntry not implemented...");
    }

  }

  registerEventListener(event: string, func: (arg: any) => void)
  {
    window?.chrome?.webview?.addEventListener(event, func);
  }

  getCallHistory(): string {
    try{
      return window?.chrome?.webview?.hostObjects.sync.interopClass.getCallHistory();
    }
    catch{
      console.log("GetCallHistory not implemented...");
      return "";
    }

  }

  getSettings(): string {
    try{
      return window?.chrome?.webview?.hostObjects.sync.interopClass.getSettings();
    }
    catch{
      console.log("GetSettings not implemented...");
      return "";
    }

  }

  setSettings(settings: string): boolean {
    try{
      return window?.chrome?.webview?.hostObjects.sync.interopClass.setSettings(settings);
    }
    catch{
      console.log("SetSettings not implemented...");
      return false;
    }

  }

  openLink(link: string)
  {
    try{
      window?.chrome?.webview?.hostObjects.sync.interopClass.openLink(link);
    }
    catch{
      console.log("OpenLink not implemented...");
    }

  }

  getBasicConfig()
  {
    try{
      return JSON.parse(window?.chrome?.webview?.hostObjects.sync.interopClass.getBasicConfig());
    }
    catch{
      console.log("GetBasicConfig not implemented...");
      return {  mainUrl: "",
        apiVersion: "",
        errorUrl: "",
        error: {
          errorCode: 0,
          errorMessage: ""
        },
        hintUrl: "",
        hintText: ""};
    }

  }
}

import { EventEmitter, Injectable, Output } from '@angular/core';
import { ErrorResponse } from '../models/alert.model';
import { IConnectorScheme, ICrmQuery } from '../component/crm-connector/dynamic-connector/dynamic-connector.component';
import { ActionMapping } from '../models/caller.model';
import { WebViewInteropService } from './web-view-interop.service';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { basicConfig } from '../models/global.model';
import { LoggerService } from './logger.service';

export interface IClientConfig {
  clientVersion: string;
  callHistoryPath: string;
  logPath: string;
  zoomFactor: number;
  useTrayIcon: boolean;
}

export interface IConnectorConfig {
  error: ErrorResponse,
  data: [
    {
      connectorId: string,
      connectorType: string,
      connectorName: string,
      connectorSetting: {
        mainFqdn: string,
        mainPath: string,
        applicationId: string
      },
      crmQueries: ICrmQuery[],
      schema: IConnectorScheme[],
      actions: ActionMapping[]
    }
  ]
}

const httpConnectorEndpoint = environment.apiUri + environment.connectorConfQuery + environment.apiCode;

@Injectable({
  providedIn: 'root'
})
export class ConfigService {
  private crmConfig!: IConnectorConfig;

  @Output() configComplete$ = new EventEmitter<boolean>(false);

  constructor(private interopService: WebViewInteropService, private httpClient: HttpClient, private logger: LoggerService) { }


  fetchAllConfigs() {
    this.logger.log("Feching CRM Data...");
    this.httpClient.get<IConnectorConfig>(httpConnectorEndpoint)
      .subscribe(
        {
          next: (result) => { //get the settings for the connector
            this.logger.log('CRM Connector: ', result);
            if (result.error.errorCode === 0) //if we don't receive an error, we can lazy load the component
            {
              this.crmConfig = result;
              this.configComplete$.emit(true);
            }
            else {
              this.configComplete$.emit(false);
              throw (result.error);
            }
          },
          error: (error) => {
            this.logger.error("DynamicConnector: ", error);
          }
        });
  }


  //#region Client Configuration 
  getClientConfiguration(): IClientConfig {
    return JSON.parse(this.interopService.getSettings());
  }

  setClientConfiguration(config: IClientConfig): boolean {
    this.interopService.setSettings(JSON.stringify(config));
    return true;
  }
  //#endregion

  getCrmConfiguration(): IConnectorConfig {
    return this.crmConfig;
  }

  getBasicConfig(): basicConfig {
    return this.interopService.getBasicConfig();
  }

}

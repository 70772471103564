import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { Action } from 'src/app/models/caller.model';
import { LoggerService } from 'src/app/service/logger.service';
import { WebViewInteropService } from 'src/app/service/web-view-interop.service';

export enum IconType {
  phone = "phone",
  mail = "mail",
  link = "link",
  eye = "eye"
}

@Component({
  selector: 'app-action-button',
  templateUrl: './action-button.component.html',
  styleUrls: ['./action-button.component.css'],
  imports: [CommonModule],
  standalone: true
})
export class ActionButtonComponent {
  @Input() action!: Action | undefined;
  @Input() showText!: Boolean;
  iconType = IconType;

  constructor(private webViewInterop: WebViewInteropService, private logger: LoggerService){}

  execAction() {
    if (this.action) {
      this.logger.log("Execute action", this.action.url);
      if (this.action.url.startsWith("http")) {
        this.openLink(this.action.url);
      }
      else if (this.action.url.startsWith("callto")) {
        this.openPhone(this.action.url)
      }
      else if (this.action.url.startsWith("mailto")) {
        window.open(this.action.url, "_self");
      }
      else {
        this.logger.warn("Unknown action: ", this.action.url);
      }
    }

  }

  open(url: string)
  {
    this.webViewInterop.openLink(url);
  }

  openLink(url: string)
  {
    if(url.startsWith("http"))
    {
      this.webViewInterop.openLink(url);
    }
    else
    {
      this.webViewInterop.openLink("http://" + url);
    }
  }

  openPhone(url: string)
  {
    this.webViewInterop.openLink(url.replace(" ",""));
  }
}

import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Router } from '@angular/router';
import { tokenResponse } from '../models/global.model';
import { environment } from 'src/environments/environment';
import { WebViewInteropService } from './web-view-interop.service';
import { ConfigService } from './config.service';
import { AuthOptions, EventTypes, OidcSecurityService, PublicEventsService } from 'angular-auth-oidc-client';
import { concatMap, filter, map, of } from 'rxjs';
import { LoggerService } from './logger.service';

@Injectable({
  providedIn: 'root'
})
/*
This service will manage the authentication for this app.
It can be used in different components as injection. So other components can access these functions and data.
 */
export class AuthService {
  endPointUri = environment.apiUri + environment.acsConfQuery + environment.apiCode;
  private readonly eventService = inject(PublicEventsService);
  constructor(private authService: OidcSecurityService, private router: Router, private http: HttpClient, private interop: WebViewInteropService, private configService: ConfigService, private logger: LoggerService) {
    this.eventService.registerForEvents()
    .pipe(filter((notification) => notification.type === EventTypes.SilentRenewFailed))
    .subscribe(async (value) => {
      console.log('SilentRenewFailed with value', value);
      await this.loginCrm();
    });
  }

  async checkAuthentication() : Promise<boolean>
  {
    return new Promise<boolean>(async (resolve, reject)=>{

      if (this.authService.getConfigurations().length > 2) {
      this.authService.checkAuthIncludingServer("Connector").subscribe(
        {
          next: (response) => {
            console.log(response);
            resolve(true);
          },
          error: (err) => {
            if(err instanceof HttpErrorResponse)
              {
                switch(err.status)
                {
                  case 700084: //expired token
                    localStorage.removeItem("Connector");
                    break;
                }
                resolve(false);
              }
          }
        }
      )}
      else{
        this.authService.checkAuthMultiple().subscribe(response => {
          console.log(response);
          resolve(true);
      });
      }


    })
  }


  async loginCrm() : Promise<boolean>
  {
    console.log("CRM Login");
    return new Promise<boolean>(async (resolve, reject)=>{
      this.authService.authorizeWithPopUp(undefined, undefined, "Connector")
      .subscribe(result => {
        if (result.isAuthenticated) {
          this.router.navigate(['/home'], { skipLocationChange: true });
          resolve(true);
        }
        else
        {
          this.logger.warn(result);
          resolve(false);
        }
      });
    });
  }

  //#region Login
  async autoLogin() :Promise<boolean> {
    //await this.checkAuthentication(); //Let's check if we are already authenticated from last time
    return new Promise<boolean>(async (resolve, reject)=>{
      //this.logger.log("Automatically login user...")
      this.authService.isAuthenticated().subscribe(
        async isAuthenticated => {
          if(isAuthenticated)
          {
            this.logger.log("Automated Login. Is authenticated.");
            /*
            We have to differentiate between the loaded configuration in the authentication lib. So for the beginning we will have 2 configs (AzureAd and ACS which are hardcoded). After we fetch an additional configuration from our api, we will have 3 configs
            */
            if (this.authService.getConfigurations().length > 2) {
              this.authService.isAuthenticated("Connector")
                .subscribe(async result => {
                  if (result) {
                    this.configService.fetchAllConfigs();
                    this.logger.log("CRM Login finished...");
                    this.router.navigate(['/home'], { skipLocationChange: true });
                    resolve(true);
                  }
                  else {
                    const result = await this.loginCrmAndRedirect();
                    resolve(result);
                  }
                })
            }
            else {
              const result = this.loginMultiplePopup();
              resolve(result);
            }
          }
          else
          {
            this.logger.log("Automated Login. Not authenticated...");
            resolve(false);
          }
        }
      )
    })
  }

  async loginCrmAndRedirect() : Promise<boolean>
  {
    console.log("CRM Login");
    this.authService.isAuthenticated$.subscribe(result=>console.log(result));
    return new Promise<boolean>(async (resolve, reject)=>{
      this.authService.authorizeWithPopUp(undefined, undefined, "Connector")
      .subscribe(result => {
        if (result.isAuthenticated) {
          this.configService.fetchAllConfigs();
          this.logger.log("CRM Login finished...");
          this.router.navigate(['/home'], { skipLocationChange: true });
          resolve(true);
        }
        else
        {
          this.logger.warn(result);
          resolve(false);
        }
      });
    });
  }

  async loginAcs() : Promise<boolean>
  {
    console.log("Login ACS");
    return new Promise<boolean>(async (resolve, reject)=>{
      this.authService.authorizeWithPopUp(undefined, undefined, "Acs")
      .subscribe(result => {
        if (result.isAuthenticated) {
          resolve(true);
        }
        else
        {
          this.logger.warn(result);
          resolve(false);
        }
      });
    });
  }

  refreshSession()
  {
    this.authService.forceRefreshSession(undefined,"AzureAd").pipe(
      concatMap(response => this.authService.forceRefreshSession(undefined, "Acs")),
      concatMap(response => this.authService.forceRefreshSession(undefined, "Connector"))
    ).subscribe((result) => {
      this.logger.log("Forced refreshing... ", result);
      if(result.isAuthenticated)
      {
        this.configService.fetchAllConfigs();
        this.router.navigate(['home'], { skipLocationChange: true });
      }
    });
  }

  loginMultiplePopup()
  {
    this.logger.log("Multiple login with popup...");
    return new Promise<boolean>(async (resolve, reject)=>{
      const authOptions: AuthOptions = {
        customParams: {
          prompt: 'none',
        }
      }
      this.authService.authorizeWithPopUp(undefined, undefined, "AzureAd").pipe(
        concatMap(response => {
          if(response.isAuthenticated)
          {
            return this.authService.authorizeWithPopUp(undefined, undefined, "Acs");
          }
          else
          {
            return of(response);
          }
          
        }),
      ).subscribe(result => {
        this.logger.log(result);

        if (result.isAuthenticated) {
          this.logger.log("AutoLogin finished...");
          /*
          After we have authenticated in azure ad, we can fetch the new crm connector config (requires a valid token  from azure ad)
          We will save the response in the local storage
          */        
          var authConfigUrl = environment.apiUri + environment.connectorAuthQuery + environment.apiCode;

          console.log(window.location);
          if(window.location.href.startsWith("https://localhost"))
          {
            authConfigUrl = authConfigUrl + "&local"
          }

          this.http.get<any>(authConfigUrl).pipe(
            map((customConfig: any) => {
                return customConfig.data[0];
              })).subscribe(
                {
                  next: result => {
                    if(result != undefined)
                    {
                      sessionStorage.setItem("CRMConf", JSON.stringify(result));
                      location.reload();                      
                    }
                    else
                    {
                      this.logger.warn("Missing connector configuration... Please add a configuration in the admin portal");
                      sessionStorage.removeItem("CRMConf"); //remove the crm conf from localstorage
                      resolve(false);
                    }
                  },
                  error: (error) => {
                    this.logger.error("Missing license");
                    reject("Missing license");
                  }
                });
        }
        else
        {
          //this.logger.log(result);
          resolve(false);
        }
      });
    });
  }

  logout() { // Add log out function here
    this.logger.log("Logging out...");
    this.authService.logoff('AzureAd')
    .subscribe((result) => this.logger.log(result));

    this.authService.logoffLocalMultiple(); //this will only empty the storage from the authentication data. It will not logoff from the server.
    sessionStorage.removeItem("CRMConf"); //remove the crm conf from localstorage
  }
  //#endregion

  getIdToken(): string {
    var idToken: string = "";
    this.authService.getIdToken("AzureAd").subscribe(
      token => {
        idToken = token;
      }); //we have to assign the new http header again (HttpHeaders will create a new header)
    return idToken;
  }

  sendTelemetryData() {
    try {
      //var infos = JSON.parse(window?.chrome?.webview?.hostObjects.sync.interopClass.getClientInformation());
      var infos = JSON.parse(this.interop.getTelemetryFromClient());
      infos.environment = environment.type;
      infos["webVersion"] = environment.version;
      //infos.additionalValues.push({ key: "Test", value: "TestVal" });
      this.logger.log("ClientData: ", infos);//Call a function from interopClass in WPF C#
      this.logger.log("Client version: ", infos.appVersion.toString());
      
      this.http.post<tokenResponse>(environment.apiUri + environment.telemetryConfQuery + environment.apiCode, infos).subscribe(
        {
          next: result => {
            this.logger.log("Telemetry: ", result);
          },
          error: (error) => {
            this.logger.log("Telemetry ", error);
          }
        }
      );
    }
    catch (err) {
      this.logger.error("Error sending telemetry: ", err);
    }
  }

  //this function is used in the tokenrefresher of the acs call client. we have to create a promise of the acs token
  async getAcsTokenRefresher(): Promise<string> {
    this.logger.log("Acquire new token for ACS...");

    return new Promise<string>((resolve, rejected) => {
      var body = {
        "teamsUserId": "",
        "teamsUserToken": "",
      }

      this.authService.forceRefreshSession(undefined, "AzureAd").pipe(
        concatMap(response => this.authService.forceRefreshSession(undefined, "Acs")),
      ).subscribe((result) => {
        this.logger.log("Forced refreshing... ");
        if (result.isAuthenticated) {
          this.authService.getUserData("AzureAd").subscribe(
            userData => {
              //check if we have userdata, otherwise we have to authenticate again
              if (userData) {
                body.teamsUserId = userData.oid
              }
              else {
                rejected("Missing userdata");
                location.reload();
              }
            }
          )

          this.authService.getAccessToken("Acs").subscribe(
            token => {
              body.teamsUserToken = token;

              this.http.post<tokenResponse>(this.endPointUri, body).subscribe(
                {
                  next: result => {
                    resolve(result.data.acsToken);
                  },
                  error: (response: HttpErrorResponse) => {
                    this.logger.error("Cannot get ACS token: ", response);
                    rejected();
                    switch (response.error.error.errorCode) {
                      case 2: //Tenant not active
                        this.logger.error(response.error.error.errorMessage);
                        throw new Error("Code " + response.error.error.errorCode + " : " + response.error.error.errorMessage);
                      case 3: //User is not licensed
                        this.logger.error(response.error.error.errorMessage);
                        throw new Error("Code " + response.error.error.errorCode + " : " + response.error.error.errorMessage);
                      default:
                        this.logger.error("Error ACS Token");
                        throw new Error("Error ACS Token");
                    }
                  }
                }
              );
            }
          )
        }
      });
    }
    );
  }
}
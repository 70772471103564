import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LoggerService {

  constructor() { }

  log(message: any, ...arg: any[])
  {
    if(arg.length > 0)
    {
      console.log(message, JSON.stringify(arg));
    }
    else
    {
      console.log(message);
    }

  }

  error(message: any, ...arg: any[]) {
    if(arg.length > 0)
    {
      console.error(message, JSON.stringify(arg));
    }
    else
    {
      console.error(message);
    }
  }

  warn(message: any, ...arg: any[]) {
    if(arg.length > 0)
    {
      console.warn(message, JSON.stringify(arg));
    }
    else
    {
      console.warn(message);
    }
  }
}

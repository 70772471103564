import { Injectable } from '@angular/core';
import { GraphService } from './graph.service';
import { WebViewInteropService } from './web-view-interop.service';
import { ICrmData, callEntry } from '../models/caller.model';
import { Observable } from 'rxjs';
import { LoggerService } from './logger.service';

@Injectable({
  providedIn: 'root'
})
export class CallDataManagerService {

  constructor(private graphService: GraphService, private interopService: WebViewInteropService, private logger: LoggerService){}

  async getCustomData(entry: callEntry){
    this.logger.log("CallDataManagerService: Waiting for crm response");
    entry.crmData.custom = (await entry.customPromise as ICrmData).custom!;
    entry.crmData.actions =  (await entry.customPromise as ICrmData).actions!;
    entry.customPromise = undefined; //we want to delete the promise here. so we can avoid duplicate awaits

    //search for the object "Name". replace displayname if object exists
    var customName = entry.crmData.custom.find(item => item.title === "Name");
    if(customName != undefined)
    {
      entry.displayName = customName.value;
    }

    //this.logger.log("CUSTOMDATANAME: ", customName);
    this.logger.log("CallDataManagerService: Saving call entry");
    this.interopService.saveCallEntry(entry);
  }

  saveCallData(entry: callEntry)
  {
    if(entry.customPromise)
    {
      //this.logger.log("CallDataManagerService: getCustomData");
      this.getCustomData(entry);
    }
    else
    {
      this.logger.log("CallDataManagerService: saveCallEntry");
      this.interopService.saveCallEntry(entry);
    }
  }

  graphResolveName(id: string): Observable<any> | undefined
  {
    var isId : boolean = false;

      if(id.includes("28:orgid:"))
      {
        id = id.replace("28:orgid:","");
        this.logger.log("CallDataManagerService: Resolving name... ", id);
        isId = true;
        
      }
      else if(id.includes("8:orgid:"))
      {
        id = id.replace("8:orgid:","");
        this.logger.log("CallDataManagerService: Resolving name... ", id);
        isId = true;
      }
      if(isId)
      {
        return this.graphService.resolveName(id);
      }
      else
      {
        return undefined;
      }
  }
}

<table>
    <col style="width: 20%;" />
    <col style="width: 99%;" />
    <col style="width: fit-content;" />
    @for (data of entry; track $index)
    {
        <tr>
            @switch (data.type) {
            @case (type.Text){
                <td>
                    <div class="title">{{data.title}}</div>
                </td>
                <td>
                    <div class="value shorten" [tooltip]="data.value">{{data.value}}</div>
                </td>
            }
            @case (type.Phone) {
                <td>
                    <div class="title">{{data.title}}</div>
                </td>
                <td><a (click)="openPhone(data.value)">
                        <div class="value shorten" [tooltip]="data.value">{{data.value}}</div>
                    </a></td>
            }
            @case (type.Link) {
                <td>
                    <div class="title">{{data.title}}</div>
                </td>
                <td><a (click)="openLink(data.value)">
                        <div class="value shorten" [tooltip]="data.value">{{data.value}}</div>
                    </a></td>
            }
            @case (type.Mail) {
                <td>
                    <div class="title">{{data.title}}</div>
                </td>
                <td><a href="mailto:{{data.value}}">
                        <div class="value shorten" [tooltip]="data.value">{{data.value}}</div>
                    </a></td>
            }
            @default {
                <td>
                    <div class="title">{{data.title}}</div>
                </td>
                <td>
                    <div class="value shorten" [tooltip]="data.value">{{data.value}}</div>
                </td>
            }
            }
            <td><app-copy-button style="width: fit-content;" [val]="data.value" /></td>
        </tr>
    }
</table>

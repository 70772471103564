import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-copy-button',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './copy-button.component.html',
  styleUrls: ['./copy-button.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush //we don't to check for changes. so we can also use the onpush strategy. Please mark as dirty if changes are done
})
export class CopyButtonComponent {
  @Input() val!: string;
  success: boolean = false;
  timerId : any;

  constructor(private cdr: ChangeDetectorRef){}

  changeState(){
    this.success = false;
    clearInterval(this.timerId);
    this.cdr.markForCheck();
  }

  copyVal()
  {
    console.log("Copy : ", this.val);
    navigator.clipboard.writeText(this.val).then(
      val => {
        this.success = true;
        this.cdr.markForCheck();
        this.timerId = setInterval(this.changeState.bind(this), 3000);
      }
    );
  }
}

import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { customData, dataType } from 'src/app/models/caller.model';
import { TooltipDirective } from '../tooltip.directive';
import { WebViewInteropService } from 'src/app/service/web-view-interop.service';
import { CopyButtonComponent } from '../copy-button/copy-button.component';

@Component({
  selector: 'app-data-table',
  standalone: true,
  imports: [CommonModule, TooltipDirective, CopyButtonComponent],
  templateUrl: './data-table.component.html',
  styleUrls: ['./data-table.component.css']
})
export class DataTableComponent {
  @Input() entry!: customData[];
  type = dataType;

  constructor(private webViewInterop: WebViewInteropService){};

  openLink(url: string)
  {
    if(url.startsWith("http"))
    {
      this.webViewInterop.openLink(url);
    }
    else
    {
      this.webViewInterop.openLink("http://" + url);
    }

  }

  openPhone(url: string)
  {
    this.webViewInterop.openLink("callto:"+ url.replace(" ",""));
  }

}

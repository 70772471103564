export enum dataType {
    Text = "text",
    Mail = "mail",
    Link = "link",
    Phone = "phone"
}

export enum teamsCallType {
    microsoftBot = "microsoftBot",
    microsoftTeamsApp = "microsoftTeamsApp",
    phoneNumber = "phoneNumber",
    microsoftTeamsUser = "microsoftTeamsUser"
}

// enum for call results
export enum callResult {
    Accepted = "Accepted",
    Declined = "Declined",
    Unanswered = "Unanswered",
    Unknown = "Unkown",
    Ongoing = "Ongoing"
}

// custom data from the connectors
export class customData {
    constructor(
        public title: string,
        public value: string,
        public type?: string
      ) { }
}

export interface ICrmData{
    custom?: customData[];
    actions?: Action[];
}

export interface callEntry {
    id: string;
    type: teamsCallType;
    timestamp: number;
    displayName: string;
    number: string;
    result: callResult;
    acceptedBy: string;
    transferredBy: string;
    forwardedBy: string;
    crmData: ICrmData;
    customPromise? : Promise<unknown>;
}

export interface ActionMap
{
  key: string;
  table: string;
  value: string;
}

export interface ActionMapping
{
    title: string;
    icon: string;
    url: string;
    mapping: ActionMap[];
}

export interface Action
{
    title: string;
    icon: string;
    url: string;
}